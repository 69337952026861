//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import jq from "jquery";
export default {
  data() {
    return {
      topBannerContent: [
        /**{
          name: "UC Towns",
          img: "/img/uc_towns/ucTowns_interior_slider3.jpg",
          img_M: "/img/uc_towns/ucTowns_interior_slider3.jpg",
          bgPosition: "20% center",
          logo: "/img/comminitiesLogo_no_margin_top/uc-towns-logo.png",
          line1: "THE FINAL PHASE OF ",
          line2: "OSHAWA’S GREATEST COMMUNITY",
          btnLink: "/uc_towns",
          btnText: "Coming Soon",
          line3: "Oshawa | Townhomes",
          redBtn: true,
        },*/
        {
          name: "U.C. Tower 3 banner",
          img: "/img/homeSlider/uc-tower3-banner5.jpg",
          img_M: "/img/homeSlider/uc-tower3-banner6_M.jpg",
          imgBg_M: "contain",
          bgPosition: "center center",
          bannerLink: "/uc_tower3",
          noMiddlePart: true,
        },
        // {
        //   name: "Lindsay Heights",
        //   img: "/img/homeSlider/lindsay-news.jpg",
        //   img_M: "/img/homeSlider/lindsay-news-M.jpg",
        //   bgPosition: "center center",
        //   // logo: "/img/comminitiesLogo/lindsay_heights_logo.png",
        //   // line1: "Your Next Great Story Starts Here",
        //   // line2: "PHASE 3 COMING SOON",
        //   // btnLink: "/storybrook",
        //   // btnText: "Register Now",
        //   // line3: "30' & 50' Singles",
        //   // redBtn: true,
        //   bannerLink:"/lindsay_heights/",
        //   noMiddlePart: true,
        // },
        // {
        //   name: "Courtice Glen",
        //   img: "/img/homeSlider/courtice-news.jpg",
        //   img_M: "/img/homeSlider/lindsay-news-M.jpg",
        //   bgPosition: "center center",
        //   // logo: "/img/comminitiesLogo/lindsay_heights_logo.png",
        //   // line1: "Your Next Great Story Starts Here",
        //   // line2: "PHASE 3 COMING SOON",
        //   // btnLink: "/storybrook",
        //   // btnText: "Register Now",
        //   // line3: "30' & 50' Singles",
        //   // redBtn: true,
        //   bannerLink:"/courtice_glen",
        //   noMiddlePart: true,
        // },
        // {
        //   name: "U.C. Tower 3",
        //   img: "/img/homeSlider/uc-tower3-news.jpg",
        //   img_M: "/img/homeSlider/uc-tower3-news-M.jpg",
        //   bgPosition: "center center",
        //   // logo: "/img/comminitiesLogo/lindsay_heights_logo.png",
        //   // line1: "Your Next Great Story Starts Here",
        //   // line2: "PHASE 3 COMING SOON",
        //   // btnLink: "/storybrook",
        //   // btnText: "Register Now",
        //   // line3: "30' & 50' Singles",
        //   // redBtn: true,
        //   bannerLink:"/uc_tower3",
        //   noMiddlePart: true,
        // },
        // {
        //   name: "VuPoint2",
        //   img: "/img/homeSlider/vupoint2-news.jpg",
        //   img_M: "/img/homeSlider/vupoint2-news-M.jpg",
        //   bgPosition: "center center",
        //   // logo: "/img/comminitiesLogo/lindsay_heights_logo.png",
        //   // line1: "Your Next Great Story Starts Here",
        //   // line2: "PHASE 3 COMING SOON",
        //   // btnLink: "/storybrook",
        //   // btnText: "Register Now",
        //   // line3: "30' & 50' Singles",
        //   // redBtn: true,
        //   bannerLink:"/vupoint2",
        //   noMiddlePart: true,
        // },
        // {
        //   name: "LindsayEvent",
        //   img: "/img/homeSlider/Lindasay_event1.jpg",
        //   img_M: "/img/homeSlider/Lindasay_event1_M.jpg",
        //   bgPosition: "center center",
        //   // logo: "/img/comminitiesLogo/storybrook_logo.png",
        //   // line1: "Your Next Great Story Starts Here",
        //   // line2: "PHASE 3 COMING SOON",
        //   // btnLink: "/storybrook",
        //   // btnText: "Register Now",
        //   // line3: "30' & 50' Singles",
        //   // redBtn: true,
        //   bannerLink:"/lindsay_heights",
        //   noMiddlePart: true,
        // },
        // {
        //   name: "U.C. Tower 3",
        //   img: "/img/homeSlider/uc-tower3-promotion.jpg",
        //   img_M: "/img/homeSlider/uc-tower3-promotion-M.jpg",
        //   bgPosition: "center center",
        //   // logo: "/img/comminitiesLogo/storybrook_logo.png",
        //   // line1: "Your Next Great Story Starts Here",
        //   // line2: "PHASE 3 COMING SOON",
        //   // btnLink: "/storybrook",
        //   // btnText: "Register Now",
        //   // line3: "30' & 50' Singles",
        //   // redBtn: true,
        //   bannerLink:"/uc_tower3",
        //   noMiddlePart: true,
        // },
        {
          name: "Gates of Nobleton",
          img: "/img/homeSlider/gates_of_nobleton_slider2.jpg",
          img_M: "/img/homeSlider/gates_of_nobleton_slider2_M.jpg",
          bgPosition: "30% center",
          logo: "/img/comminitiesLogo/gates_of_nobleton_brown2.png",
          line1: "The Return of Nobleton's Spectacular ",
          line2: "Distinguished Residences",
          line2a: "Only 22 Lots Available",
          btnLink: "/gates_of_nobleton",
          btnText: "Now Selling",
          line3: "Nobleton | 50’, 60’, 70’ & 80’ designs",
          redBtn: true,
        },
        {
          name: "Tribute Communities",
          img: "/img/homeSlider/tribute_video_slider.jpg",
          img_M: "/img/homeSlider/tribute_video_slider.jpg",
          bgPosition: "20% center",
          logo: "/img/comminitiesLogo/tribute-40.png",
          line1: "A Tradition of Excellence for 40 Years",
          // line2: "FROM THE HIGH $500'S",
          img_btn: "/img/play-circle-regular.svg",
          btnLink: "",
          btnText: "",
          // line3: "Pickering | Condos",
          redBtn: false,
          videoUrl: "https://youtu.be/TRuNwC2XCJ8?autoplay=1&mute=1",
          title: "",
        },
        {
          name: "Storybrook",
          img: "/img/homeSlider/storybrook_slider.jpeg",
          img_M: "/img/homeSlider/storybrook_slider_M.png",
          bgPosition: "30% center",
          logo: "/img/comminitiesLogo/storybrook_logo.png",
          line1: "Your Next Great Story Starts Here",
          line2: "NEW RELEASE OF 30’ & 50’ SINGLES",
          btnLink: "/storybrook",
          btnText: "Now Selling",
          line3: "30' & 50' Singles",
          redBtn: true,
        },
        // {
        //   name: "Lindsay Heights",
        //   img: "/img/homeSlider/lindsay_heights_slider.jpg",
        //   img_M: "/img/homeSlider/lindsay_heights_slider_M.jpg",
        //   bgPosition: "20% center",
        //   logo: "/img/comminitiesLogo/lindsay_heights_logo.png",
        //   line1: "HWY 35 & Thunder Bridge Rd.",
        //   line2: "",
        //   btnLink: "/lindsay_heights",
        //   btnText: "Now Selling",
        //   line3: "Lindsay | Freehold Towns, Semi's, 30', 36'& 40'",
        //   redBtn: true,
        // },
        {
          name: "Lindsay Heights",
          style: "Lindsay",
          img: "/img/homeSlider/lindsay_heights_slider3.jpg",
          img_M: "/img/homeSlider/lindsay_heights_slider3_M.jpg",
          bgPosition: "20% center",
          logo: "/img/comminitiesLogo/lindsay_heights_logo.png",
          line1: "HWY 35 & Thunder Bridge Rd.",
          line2: "",
          btnLink: "/lindsay_heights",
          btnText: "Now Selling",
          line3: "7 DESIGNER DECORATED SHOWHOMES IN LINDSAY",
          line4: "",
          redBtn: true,
        },
        // {
        //   name: "U.C. Tower 3",
        //   img: "/img/homeSlider/ucTower3_slider6.jpg",
        //   img_M: "/img/homeSlider/ucTower3_slider6_m.jpg",
        //   bgPosition: "center center",
        //   logo: "/img/comminitiesLogo/uctower3-logo.png",
        //   line1: "FROM THE LOW $500's",
        //   line2: "",
        //   btnLink: "/uc_tower3",
        //   btnText: "Now Selling",
        //   line3: "Oshawa | Condos",
        //   redBtn: true,
        // },
        // {
        //   name: "VuPoint",
        //   img: "/img/homeSlider/vupoint_slider3.jpg",
        //   img_M: "/img/homeSlider/vupoint_slider3_m.jpg",
        //   bgPosition: "20% center",
        //   logo: "/img/comminitiesLogo/vupoint-logo.png",
        //   line1: "LAKE VIEWS FROM EVERY SUITE",
        //   line2: "FROM THE LOW $600'S",
        //   btnLink: "/vupoint",
        //   btnText: "Now Selling",
        //   line3: "Pickering | Condos",
        //   redBtn: true,
        // },
        {
          name: "VuPoint2",
          img: "/img/homeSlider/vupoint2_slider2.jpg",
          img_M: "/img/homeSlider/vupoint2_slider2_M.jpg",
          bgPosition: "20% center",
          logo: "/img/comminitiesLogo/vupoint2-logo.png",
          line1: "LAKE VIEWS FROM EVERY SUITE",
          line2: "FROM THE LOW $600'S",
          btnLink: "/vupoint2",
          btnText: "Now Selling",
          line3: "Pickering | Condos",
          redBtn: true,
        },
        // {
        //   name: "Victoria Condos",
        //   img: "/img/homeSlider/victoria_slider.jpg",
        //   img_M: "/img/homeSlider/victoria_slider_M.jpg",
        //   bgPosition: "30% center",
        //   logo: "/img/comminitiesLogo/victoria_logo.png",
        //   line1: "Your Worlds Within Reach",
        //   line2: "Victoria",
        //   btnLink: "/victoria",
        //   btnText: "Register Now",
        //   line3: "North York | Condos",
        //   redBtn: true,
        // },
        // {
        //   "3equalCol": true,
        //   contents: [
        //     {
        //       name: "TRIBUTE COMMUNITIES",
        //       img: "/img/homeSlider/multiComminty/trib01.jpg",
        //       img_M: "/img/homeSlider/multiComminty/trib01-m.jpg",
        //       bgPosition: "center center",
        //       sliceLink: "",
        //     },
        //     {
        //       name: "VuPoint 2",
        //       img: "/img/homeSlider/multiComminty/trib02-vupoint.jpg",
        //       img_M: "/img/homeSlider/multiComminty/trib02-vupoint-m.jpg",
        //       bgPosition: "center center",
        //       sliceLink: "/vupoint",
        //     },
        //     {
        //       name: "U.C. TOWER 3",
        //       img: "/img/homeSlider/multiComminty/trib03-uc-tower3.jpg",
        //       img_M: "/img/homeSlider/multiComminty/trib03-uc-tower3-m.jpg",
        //       bgPosition: "center center",
        //       sliceLink: "/uc_tower3",
        //     },
        //   ],
        // },

        // {
        //   name: "Brooklin Trails",
        //   img: "/img/homeSlider/brooklin_trails.png",
        //   img_M: "/img/homeSlider/brooklin_trails_M.png",
        //   bgPosition: "20% center",
        //   logo: "/img/comminitiesLogo/brooklin_trails-logo.png",
        //   line1: "LOREM IPSUM DOLOR SIT",
        //   line2: "SED DO EIUSMOD",
        //   btnLink: "/brooklin_trails",
        //   btnText: "Register Now",
        //   line3: "Brooklin | Towns & Singles",
        //   redBtn: true,
        // },
        // {
        //   name: "Noyo",
        //   img: "/img/homeSlider/noyo_slider.jpg",
        //   img_M: "/img/homeSlider/noyo_slider_M.jpg",
        //   bgPosition: "20% center",
        //   logo: "/img/comminitiesLogo/noyo-logo.png",
        //   line1: "Bayview Ave. & Sheppard Ave. E.",
        //   line2: "COMING SOON",
        //   btnLink: "/noyo",
        //   btnText: "Register Now",
        //   line3: "North York | Condos",
        //   redBtn: true,
        // },
        // {
        //   name: "The Jopling",
        //   img: "/img/homeSlider/jopling_slider.jpg",
        //   img_M: "/img/homeSlider/jopling_slider_M.jpg",
        //   bgPosition: "20% center",
        //   logo: "/img/comminitiesLogo/jopling_logo_transparent.png",
        //   line1: "Bloor St. W. & Kipling Ave.",
        //   line2: "COMING SOON",
        //   btnLink: "/jopling",
        //   btnText: "Register Now",
        //   line3: "Etobicoke | Condos",
        //   redBtn: true,
        // },
        {
          name: "Courtice Glen",
          img: "/img/homeSlider/courtice_glen_slider2.jpg",
          img_M: "/img/homeSlider/courtice_glen_slider2_M.jpg",
          bgPosition: "20% center",
          logo: "/img/comminitiesLogo_no_margin_top/courtice_glen_logo1.png",
          line1: "Bloor St. & Trulls Rd.",
          line2: "",
          btnLink: "/courtice_glen",
          btnText: "Now Selling",
          line3: "Courtice | Towns, Semis & Singles",
          redBtn: true,
        },
        // {
        //   name: "Storybrook",
        //   img: "/img/homeSlider/storybrook_slider.png",
        //   img_M: "/img/homeSlider/storybrook_interior_slider_M.png",
        //   bgPosition: "20% center",
        //   logo: "/img/comminitiesLogo/storybrook_logo.png",
        //   line1: "Your Next Great Story Starts Here",
        //   line2: "COMING SOON",
        //   btnLink: "/storybrook",
        //   btnText: "Register Now",
        //   line3: "Fergus | Towns & Singles",
        //   redBtn: true,
        // },
        // {
        //   name: "Cobourg Trails",
        //   img: "/img/homeSlider/cobourgTrails_interior_slider.jpg",
        //   img_M: "/img/homeSlider/cobourgTrails_interior_slider_M.jpg",
        //   bgPosition: "20% center",
        //   logo: "/img/comminitiesLogo/CobourgTrails-Logo.png",
        //   line1: "Discover Your Trail Home",
        //   line2: "COMING SOON",
        //   btnLink: "/cobourg_trails",
        //   btnText: "Register Now",
        //   line3: "Cobourg | Towns & Singles",
        //   redBtn: true,
        // },
        // {
        //   name: "Artistry",
        //   img: "/img/homeSlider/Artistry_slider.jpg",
        //   img_M: "/img/homeSlider/Artistry_slider.jpg",
        //   bgPosition: "center center",
        //   logo: "/img/comminitiesLogo/artistrylogo.png",
        //   line1: "Inspired by Art.",
        //   line2: "Immersed in Urban Living.",
        //   btnLink: "/artistry",
        //   btnText: "Now Open",
        //   line3: "Toronto | Condos",
        // },
        // {
        //   name: "Colgan Crossing",
        //   img: "/img/homeSlider/colgancrossing_slider.jpg",
        //   img_M: "/img/homeSlider/colgancrossing_slider.jpg",
        //   bgPosition: "center center",
        //   logo: "/img/comminitiesLogo/colgancrossing-logo.png",
        //   line1: "the crossroads of",
        //   line2: "modern country living",
        //   btnLink: "/colgan_crossing",
        //   btnText: "Now Selling",
        //   line3: "Colgan | Community",
        //   redBtn: true,
        // },
        // {
        //   name: "Cobourg Trails",
        //   img: "/img/homeSlider/cobourgTrails_interior_slider2.jpg",
        //   img_M: "/img/homeSlider/cobourgTrails_interior_slider2.jpg",
        //   bgPosition: "65% center",
        //   logo: "/img/comminitiesLogo/CobourgTrails-Logo.png",
        //   line1: "Cobourg's New",
        //   line2: "Landmark Community",
        //   btnLink: "/cobourg_trails",
        //   btnText: "Sold Out",
        //   line3: "Cobourg | Community",
        // },
        // {
        //   name: "Rouge Valley",
        //   img: "/img/homeSlider/towns_at_rouge_slider.jpg",
        //   img_M: "/img/homeSlider/towns_at_rouge_slider.jpg",
        //   bgPosition: "center center",
        //   logo: "/img/comminitiesLogo/townsatrougevalley_logo.png",
        //   line1: "Toronto’s All Natural",
        //   line2: "Urban Lifestyle",
        //   btnLink: "/rouge_valley",
        //   btnText: "Move in Today",
        //   line3: "East Toronto | Stacked Towns",
        // },
        // {
        //   name: "Parkside",
        //   img: "/img/homeSlider/parkside_slider.jpg",
        //   img_M: "/img/homeSlider/parkside_slider.jpg",
        //   bgPosition: "85% center",
        //   logo: "/img/comminitiesLogo/parksidesquare_logo.png",
        //   line1: "Toronto’s All Natural",
        //   line2: "Urban Lifestyle",
        //   btnLink: "https://www.parksidesquare.com",
        //   btnText: "Now Open",
        //   line3: "Toronto | Rentals",
        // },
        // {
        //   name: "Stanley",
        //   img: "/img/homeSlider/stanley_slider.jpg",
        //   img_M: "/img/homeSlider/stanley_slider.jpg",
        //   bgPosition: "85% center",
        //   logo: "/img/comminitiesLogo/stanley_logo.png",
        //   line1: "Toronto | Condos at Church & Carlton",
        //   line2: "",
        //   btnLink: "/stanley",
        //   btnText: "Move in Today",
        //   line3: "Toronto | Condos",
        // },
      ],
      bannerSlick: {
        slidesToShow: 1,
        arrows: true,
        slidesToScroll: 1,
        dots: true,
        fade: true,
        autoplay: false,
        autoplaySpeed: 5000,
        lazyLoad: "ondemand",
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              fade: true,
              autoplay: false,
              autoplaySpeed: 5000,
              lazyLoad: "ondemand",
            },
          },
          {
            breakpoint: 450,
            settings: {
              arrows: false,
              dots: true,
              fade: true,
              autoplay: false,
              autoplaySpeed: 5000,
              lazyLoad: "ondemand",
            },
          },
        ],
      },
      sliderH: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.currentPageloaded = true;

      setTimeout(() => {
        if (this.currentPageloaded == true) {
          this.checkHashVideo();
        }
      }, 1000);
    });
  },
  methods: {
    // getSliderH() {
    //   this.sliderH = jq(".topSliderItemLayout").width() * 0.3;
    // }
    checkHashVideo() {
      let hash = this.$route.query["goto"];
      if (hash != undefined && hash === "open-video") {
        this.playFancyboxVideo();
      }
    },
    playFancyboxVideo() {
      jq(".playBtn:first").click();
    },
  },
  computed: {
    // getSliderH() {
    // return jq(".topSliderItemLayout").width() * 0.75;
    // }
  },
};
